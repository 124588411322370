// Commonly used web colors
$black: #3b3633;            // Black
$white: #fafafa;            // White
$red-600: #a3360a;
$red-500: #a3360a;
$blurple-600: #40b6c5;
$blurple-500: #48b9c7;
$blurple-400: #48b9c7;
$blurple-300: #48b9c7;
$grey-600: #403937; // Topaz
$grey-100: #f3e2da; // Topaz

$success-green: #3e935b;    // Padua
$error-red: $red-500;        // Cerise
$warning-red: #f9dfb6;      // Sunset Orange
$gold-star: #faa41a;        // Dark Goldenrod

$base-shadow-color: #3b3633;
$base-overlay-background: #3b3633;
$base-border-color: #fafafa;
$simple-background-color: #fafafa;
$primary-text-color: #fafafa;
$valid-value-color: #3e935b;
$error-value-color: $warning-red;

// Values from the classic Mastodon UI
$classic-base-color: #49423e;         // Midnight Express
$classic-primary-color: #c9c7c5;      // Echo Blue
$classic-secondary-color: #ebe9e8;    // Pattens Blue
$classic-highlight-color: #48b9c7;    // Summer Sky

$ui-base-color: #49423e;         // Midnight Express
$ui-primary-color: #c9c7c5;      // Echo Blue
$ui-secondary-color: #ebe9e8;    // Pattens Blue
$ui-highlight-color: #48b9c7;    // Summer Sky

